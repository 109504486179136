<template>
  <a-modal
    v-model="addPlaceVisible"
    title="批量添加广告位"
    width="1231px"
  >
    <template slot="footer">
      <a-button @click="handleCancel">取消</a-button>
      <a-button :disabled="isLoading" type="primary" @click="handleSubmit">确定</a-button>
    </template>
    <div class="btn-box">
      <a-button class="btn" type="primary" @click="handleAddPlace">添加广告位</a-button>
    </div>
    <a-form-model ref="ruleForm" :model="formquery" :rules="rules" v-bind="layout">
      <el-table :data="formquery.childList" style="width: 100%" class="table">
        <el-table-column label="操作" min-width="160">
          <div class="action-box" slot-scope="{ row, $index }">
            <a-button type="link" class="primary-text" @click="handleCopy(row)">复制</a-button>
            <a-button
              v-if="formquery.childList.length > 1"
              type="link"
              class="primary-text"
              @click="handleDelete($index)"
            >删除</a-button
            >
          </div>
        </el-table-column>
        <!-- 供应商 -->
        <el-table-column label="供应商" min-width="180">
          <template slot-scope="{ row, $index }">
            <a-form-model-item :prop="`childList[${$index}].publisherId`" :rules="[{ required: true, message: '请选择供应商', trigger: 'change' }]">
              <m-select
                style="width: 100%"
                v-model="row.publisherId"
                :dataList="supplierList"
                :onlyLabel="false"
                label="供应商"
              />
            </a-form-model-item>
          </template>
        </el-table-column>
        <!-- 广告样式 -->
        <el-table-column label="广告样式" min-width="150">
          <template slot-scope="{ row, $index }">
            <a-form-model-item :prop="`childList[${$index}].position`" :rules="[{ required: true, message: '请选择广告样式', trigger: 'change' }]">
              <m-select
                style="width: 100%"
                v-model="row.position"
                :dataList="positionList"
                :onlyLabel="false"
                label="广告样式"
              />
            </a-form-model-item>
          </template>
        </el-table-column>
        <!-- 广告位名称 -->
        <el-table-column label="广告位名称" min-width="200">
          <template slot-scope="{ row, $index }">
            <a-form-model-item :prop="`childList[${$index}].name`" :rules="[{ required: true, message: '请输入广告位名称', trigger: 'blur' }]">
              <a-input style="width: 100%" v-model.trim="row.name" placeholder="请输入广告位名称"></a-input>
            </a-form-model-item>
          </template>
        </el-table-column>
        <!-- 自定义广告位ID -->
        <el-table-column label="自定义广告位ID" min-width="160">
          <template slot-scope="{ row, $index }">
            <a-form-model-item :prop="`childList[${$index}].customPlaceId`">
              <a-input style="width: 100%" v-model.trim="row.customPlaceId" placeholder="请输入自定义广告位ID"></a-input>
            </a-form-model-item>
          </template>
        </el-table-column>
        <!-- 状态 -->
        <el-table-column label="状态" min-width="100">
          <template slot-scope="{ row, $index }">
            <a-form-model-item :prop="`childList[${$index}].sts`">
              <a-switch size="small" :checked="row.sts === 'A'" @change="handleChangeSts($event, row)"></a-switch>
            </a-form-model-item>
          </template>
        </el-table-column>
        <!-- 利润策略 -->
        <el-table-column label="利润策略" min-width="120" v-if="+isAdmin !== 2">
          <template>
            <a-form-model-item>
              <span>继承上级</span>
            </a-form-model-item>
          </template>
        </el-table-column>
        <el-table-column v-if="hasSubmit" label="失败原因" fixed="right" width="180">
          <template slot-scope="{ row }">
            <a-form-model-item>
              <span style="color: red">{{ row.message }}</span>
            </a-form-model-item>
          </template>
        </el-table-column>
      </el-table>
    </a-form-model>
  </a-modal>
</template>

<script>
import { batchAddPlace } from '@/apiForManage/flow/place'
import MixGetList from '@/mixins/getListForManage'
import { mapState } from 'vuex'
export default {
  mixins: [MixGetList],
  data () {
    return {
      layout: {
      },
      formquery: {
        childList: []
      },
      rules: {},
      hasSubmit: false,
      isLoading: false
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    visible: {
      handler (val) {
        if (val) {
          this.hasSubmit = false
          this.formquery.childList = []
          this.handleAddPlace()
          this.$nextTick(() => {
            this.$refs.ruleForm.clearValidate()
          })
        }
      },
      deep: true,
      immediate: true
    }
  },
  created () {
    this.getSupplierList()
    this.getPositionList()
  },
  computed: {
    ...mapState({
      isAdmin: (state) => state.user.isAdmin,
      skip: (state) => state.user.skip,
      role: (state) => state.user.roles[0],
      cur: (state) => state.user.cur,
      mediaAccess: (state) => state.user.mediaAccess
    }),
    addPlaceVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    }
  },
  methods: {
    handleChangeSts (e, row) {
      row.sts = e ? 'A' : 'S'
    },
    handleAddPlace () {
      this.$set(this.formquery.childList, this.formquery.childList.length, {
        name: undefined,
        position: undefined,
        publiserId: undefined,
        customPlaceId: undefined,
        sts: 'A',
        message: undefined
      })
    },
    handleCopy (row) {
      this.$set(this.formquery.childList, this.formquery.childList.length, { ...row })
    },
    handleDelete (index) {
      this.formquery.childList.splice(index, 1)
    },
    handleCancel () {
      this.addPlaceVisible = false
    },
    handleSubmit () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          this.isLoading = true
          try {
            const resp = await batchAddPlace(this.formquery)
          if (resp.code === 200) {
            const { data = [] } = resp
            this.formquery.childList.forEach((item, index) => {
              item.message = data[index].message
            })
            const failList = this.formquery.childList.filter((item, index) => !+data[index].success)
            this.hasSubmit = true
            if (failList.length) {
              this.$message.error('部分广告位未创建成功！')
              this.formquery.childList = failList
            } else {
              this.$message.success('添加成功！')
              this.addPlaceVisible = false
            }
            this.$emit('handleAddSuccess')
          }
          } finally {
            this.isLoading = false
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.btn-box {
  margin-bottom: @smallMargin;
  .btn {
    width: 130px;
  }
}
.action-box{
  margin-bottom: 12px;
}
.table{
  border: 1px solid @compBorderColor;
  border-radius: @smallRadius;
}
::v-deep{
  td{
    padding-bottom: 0!important;
  }
}
</style>
